<template>
  <main>
    <div class="container">
      <div class="auth-content text-center mx-auto mt-5 py-5">
        <div class="page-404">
          <h1>404</h1>
          <h2 class="text-primary">Page not found</h2>
          <p class="mt-5 mb-3">Oops, Something went missing...</p>
          <cr-button :to="{ name: 'index' }">Go to home</cr-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    layout: 'empty',
  };
</script>

<style scoped lang="sass">
  .auth-content
    width: 460px
    max-width: 100%
    position: relative
    border-radius: 3px
    padding: 1.875rem
    box-shadow: var(--box-shadow-big)
    background-color: #fff
  h1, h2, h3
    line-height: 1.2
  h1
    font-size: 4.5rem
    font-weight: 300
    margin-top: 0
    margin-bottom: 0.5rem
  h2
    font-size: 28px
    font-weight: 400
    margin-top: 0
    margin-bottom: 0.5rem

  .page-404
    position: relative
    z-index: 1
</style>
